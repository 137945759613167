import { atom } from "jotai";

export const brandAtom = atom("");
export const brandArrayAtom = atom([]);
export const moldAtom = atom([]);
export const plasticAtom = atom([]);
export const colorAtom = atom([]);
export const siteAtom = atom([]);
export const sortAtom = atom(["date", false]);
export const minAtom = atom(100);
export const maxAtom = atom(200);
export const searchAtom = atom("");
export const speedMaxAtom = atom(15);
export const speedMinAtom = atom(1);
export const glideMaxAtom = atom(7);
export const glideMinAtom = atom(1);
export const turnMaxAtom = atom(1);
export const turnMinAtom = atom(-5);
export const fadeMaxAtom = atom(5);
export const fadeMinAtom = atom(0);
export const stableAtom = atom([]);
export const typeAtom = atom([]);
export const hiddenAtom = atom(true);
export const featureAtom = atom("Featured");
export const filterAtom = atom(false);
export const urlAtom = atom("");
export const limitAtom = atom(5);

export const zeroAtom = atom([]);

export const diamMinAtom = atom(21);
export const diamMaxAtom = atom(25);
export const heightMinAtom = atom(1);
export const heightMaxAtom = atom(3);
export const rimDepthMinAtom = atom(1);
export const rimDepthMaxAtom = atom(3);
export const rimWidthMinAtom = atom(0.5);
export const rimWidthMaxAtom = atom(3);
export const insideRimDiameterMinAtom = atom(15);
export const insideRimDiameterMaxAtom = atom(23);
export const rimRatioMinAtom = atom(5);
export const rimRatioMaxAtom = atom(10);
export const rimConfigMinAtom = atom(25);
export const rimConfigMaxAtom = atom(115);
