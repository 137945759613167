"use client";
import { createTheme } from "@mantine/core";
import localFont from "next/font/local";

const punch = localFont({
  src: "../components/fonts/heading.woff2",
});
const circle = localFont({
  src: "../components/fonts/lineto-circular-book.woff2",
});

export const mantineTheme = createTheme({
  fontFamily: circle.style.fontFamily,
  headings: { fontFamily: punch.style.fontFamily },
});
