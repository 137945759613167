"use client";

import { Button } from "@mantine/core";
import { useRouter } from "next/navigation";

export default function SignInButton(props) {
  const router = useRouter();

  return (
    <Button
      color="var(--colors-bg-brand)"
      size={props.size || "sm"}
      radius="xl"
      fullWidth
      onClick={() => router.push("/signin")}
      style={{ fontFamily: "var(--mantine-font-family-headings)" }}
    >
      Sign In
    </Button>
  );
}
