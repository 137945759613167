"use client";

export default function Analytics() {
  return (
    <>
      <script
        async
        defer
        src="https://umami-theta-two.vercel.app/script.js"
        data-website-id="f954b70f-7d5c-42f2-84d4-ed8ce2edda30"
      ></script>
    </>
  );
}
