"use client";

import { css } from "@/styled-system/css";
import { HStack } from "@/styled-system/jsx";
import { useMounted } from "./use-mounted";
import { useComputedColorScheme, useMantineColorScheme } from "@mantine/core";
import Moon from "@/components/Icons/Moon";
import Sun from "@/components/Icons/Sun";

function useThemeSwitch() {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme("light", {
    getInitialValueInEffect: true,
  });

  const mounted = useMounted();
  const isDark = mounted && computedColorScheme === "dark";

  const toggleTheme = () =>
    setColorScheme(computedColorScheme === "light" ? "dark" : "light");

  const iconToUse: "Moon" | "Sun" = isDark ? "Moon" : "Sun";
  const iconText = isDark ? "Dark" : "Light";

  return {
    mounted,
    iconToUse,
    iconText,
    toggleTheme,
  };
}

const switchStyle = css({
  px: "2",
  py: "1",
  textStyle: "xl",
  fontWeight: "semibold",
  letterSpacing: "tight",
  rounded: "md",
  color: "var(--colors-bg-darktolight)",
  textDecoration: "none",
  _hover: {
    color: "brand",
  },
});

export function ThemeSwitchButton() {
  const { iconToUse, toggleTheme, mounted, iconText } = useThemeSwitch();

  return (
    <button
      style={{ opacity: mounted ? 1 : 0 }}
      onClick={toggleTheme}
      className={switchStyle}
      aria-label="Dark and Light Mode Button"
    >
      <HStack gap="2">
        {iconToUse == "Sun" && <Sun />}
        {iconToUse == "Moon" && <Moon />}
      </HStack>
    </button>
  );
}

export function ThemeSwitchIconButton() {
  const { iconToUse, iconText, toggleTheme, mounted } = useThemeSwitch();

  return (
    <button
      style={{ opacity: mounted ? 1 : 0 }}
      onClick={toggleTheme}
      aria-label={iconText}
      className={switchStyle}
    >
      {iconToUse == "Sun" && <Sun />}
      {iconToUse == "Moon" && <Moon />}
    </button>
  );
}
