"use client";

import React, { useEffect, useRef, useState } from "react";
import { searchAtom } from "@/store/atoms";
import { useAtom } from "jotai";
import { Box } from "@/styled-system/jsx";
import { CloseButton, TextInput } from "@mantine/core";
import { useSearchParams } from "next/navigation";
import Search from "../Icons/Search";

function SearchBar() {
  const [text, setText] = useAtom(searchAtom);
  const [search, setSearch] = useState("");

  const onChange = (event) => {
    setSearch(event.target.value);
  };

  const onSearch = () => {
    setText(search);
  };

  const onClear = () => {
    setSearch("");
    setText("");
  };

  const searchParams = useSearchParams();
  const isURLMounted = useRef(true);

  useEffect(() => {
    if (searchParams.has("search")) {
      setText(searchParams.get("search"));
      setSearch(searchParams.get("search"));
    }
    isURLMounted.current = false;
  }, []);

  return (
    <Box w="100%" pl={[5, 5, 20]} pr={[5, 5, 10]} maxW="1000px">
      <TextInput
        aria-label="Search Input"
        radius="xl"
        leftSection={<Search aria-label="Search" height="18" />}
        rightSectionPointerEvents="pointer"
        rightSection={text ? <CloseButton onClick={onClear} /> : null}
        value={search}
        placeholder="Search"
        variant="filled"
        onChange={onChange}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSearch();
          }
        }}
        styles={{
          section: { color: "var(--colors-dark)" },
          input: { backgroundColor: "var(--colors-gray-100)" },
        }}
      />
    </Box>
  );
}

export default SearchBar;
