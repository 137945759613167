"use client";

import { createClient } from "../../utils/supabase/client";
import { Button } from "@mantine/core";
import { useRouter } from "next/navigation";

export default function SignOutButton(props) {
  const supabase = createClient();
  const router = useRouter();

  async function handleSignOut() {
    const { error } = await supabase.auth.signOut();
    router.push("/");
    router.refresh();
    if (error) {
      // eslint-disable-next-line no-console
      //console.error("ERROR:", error);
    }
  }

  return (
    <Button
      variant="outline"
      color="var(--colors-bg-brand)"
      size={props.size || "sm"}
      radius="lg"
      fullWidth
      onClick={handleSignOut}
      style={{ fontFamily: "var(--mantine-font-family-headings)" }}
    >
      Sign Out
    </Button>
  );
}
