"use client";

import { Box, Container, em } from "@mantine/core";
import { DesktopNavBar } from "./navbar.desktop";
import { MobileNavBar } from "./navbar.mobile";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";

export const Navbar = ({ session }) => {
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  return (
    <Box position="absolute" top="6" width="full">
      <Container>
        {isMobile && <MobileNavBar session={session} />}
        {!isMobile && <DesktopNavBar session={session} />}
      </Container>
    </Box>
  );
};
