import { css } from "@/styled-system/css";
import Link from "next/link";
import { Title } from "@mantine/core";

export const NavLink = (props: {
  close: () => void;
  href: string;
  children: React.ReactNode;
}) => {
  const { href, children, close } = props;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    close();
    window.location.href = href;
  };

  return (
    <Link href={href} passHref legacyBehavior>
      <a
        onClick={handleClick}
        className={css({
          px: "2",
          py: "1",
          textDecoration: "none",
          _hover: {
            color: "brand",
          },
        })}
      >
        <Title size="20px" lineClamp={1}>
          {children}
        </Title>
      </a>
    </Link>
  );
};
